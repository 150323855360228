import _ from 'lodash'

export default {
  array: function (rows, config) {
    let data = []
    const all = []
    _.each(rows, row => {
      _.each(row[config.property], p => {
        all.push(p[config.nameProperty])
      })
    })
    _.each(_.countBy(all, x => x), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  arrayStacked: function (rows, config) {
    let data = []
    let secondaryKeys = []
    _.each(rows, row => {
      _.each(row[config.propertySecondary], p => {
        secondaryKeys.push(p[config.namePropertySecondary])
      })
    })
    secondaryKeys = _.uniq(secondaryKeys)
    _.each(rows, row => {
      _.each(row[config.propertyPrimary], p => {
        const r = {}
        r[config.propertyPrimary] = p[config.namePropertyPrimary]
        _.each(secondaryKeys, sk => {
          r[sk] = 0
        })
        data.push(r)
      })
    })
    data = _.uniqBy(data, config.propertyPrimary)
    _.each(data, rowData => {
      const inputs = _.filter(rows, row => {
        let result = false
        if (_.find(row[config.propertyPrimary], [config.namePropertyPrimary, rowData[config.propertyPrimary]])) {
          result = true
        }
        return result
      })
      _.each(inputs, row => {
        _.each(row[config.propertySecondary], r => {
          rowData[r[config.namePropertySecondary]] = rowData[r[config.namePropertySecondary]] + 1
        })
      })
    })
    return data
  },
  arraySum: function (rows, config) {
    const all = []
    const accumulator = {}
    let data = []
    _.each(rows, row => {
      _.each(row[config.property], p => {
        all.push(p[config.nameProperty])
      })
    })
    _.each(all, property => {
      accumulator[property] = 0
    })
    _.each(rows, row => {
      _.each(row[config.property], x => {
        accumulator[x[config.nameProperty]] = accumulator[x[config.nameProperty]] + row[config.sumProperty]
      })
    })
    _.each(accumulator, (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  arraySumStacked: function (rows, config) {
    let data = []
    let secondaryKeys = []
    _.each(rows, row => {
      _.each(row[config.propertySecondary], p => {
        secondaryKeys.push(p[config.namePropertySecondary])
      })
    })
    secondaryKeys = _.uniq(secondaryKeys)
    _.each(rows, row => {
      _.each(row[config.propertyPrimary], p => {
        const r = {}
        r[config.propertyPrimary] = p[config.namePropertyPrimary]
        _.each(secondaryKeys, sk => {
          r[sk] = 0
        })
        data.push(r)
      })
    })
    data = _.uniqBy(data, config.propertyPrimary)
    _.each(data, rowData => {
      const inputs = _.filter(rows, row => {
        let result = false
        if (_.find(row[config.propertyPrimary], [config.namePropertyPrimary, rowData[config.propertyPrimary]])) {
          result = true
        }
        return result
      })
      _.each(inputs, row => {
        _.each(row[config.propertySecondary], r => {
          rowData[r[config.namePropertySecondary]] = rowData[r[config.namePropertySecondary]] + row[config.sumProperty]
        })
      })
    })
    return data
  },
  directObject: function (rows, config) {
    // e.g. getting net zero year
    const data = []
    _.each(rows, row => {
      if (parseInt(row.impactpolicy_id) === parseInt(config.policyId)) {
        if (row[config.property]) {
          if (config.field in row[config.property]) {
            data.push({ name: row[config.nameProperty], value: _.toNumber(row[config.property][config.field]) })
          } /* else {
            data.push({ name: row[config.nameProperty], value: 'no measure', itemStyle: { color: '#A9A9A9' } })
          } */
        }
      }
    })
    return data
  },
  directObjectBoolean: function (rows, config) {
    // e.g. getting whether there is mandatory participation
    const data = []
    _.each(rows, row => {
      if (parseInt(row.impactpolicy_id) === parseInt(config.policyId)) {
        if (row[config.property]) {
          console.log(row[config.property])
          if (row[config.property][config.field]) {
            if (row[config.property][config.field] === 'true') {
              data.push({ name: row[config.nameProperty], value: 1 })
            }
            if (row[config.property][config.field] === 'false') {
              data.push({ name: row[config.nameProperty], value: 0 })
            }
          }
        }
      }
    })
    return data
  },
  directObjectOptions: function (rows, config) {
    // e.g. getting whether there is mandatory participation
    console.log('config data')
    console.log(config.fullField.options)
    const data = []
    _.each(rows, row => {
      if (parseInt(row.impactpolicy_id) === parseInt(config.policyId)) {
        if (row[config.property]) {
          if (row[config.property][config.field]) {
            data.push({ name: row[config.nameProperty], value: config.fullField.options.indexOf(row[config.property][config.field]) })
          }
        }
      }
    })
    return data
  },
  directObjectPure: function (rows, config) {
    let data = []
    _.each(rows, row => {
      if (parseInt(row.impactpolicy_id) === parseInt(config.policyId)) {
        if (row[config.property]) {
          if (row[config.property][config.field]) {
            data.push({ name: row[config.nameProperty], value: _.toNumber(row[config.property][config.field]) })
          }
        }
      }
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  directObjectMean: function (rows, config) {
    // e.g. getting net zero year
    let data = []
    _.each(rows, row => {
      if (row[config.property]) {
        const line = { name: row[config.nameProperty], data: [], value: 0 }
        _.each(config.field, x => {
          if (row[config.property][x]) {
            line.data.push(_.toNumber(row[config.property][x]))
          }
        })
        line.value = _.mean(line.data)
        data.push(line)
      }
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  object: function (rows, config) {
    let data = []
    _.each(_.countBy(rows, x => x[config.property][config.nameProperty]), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  obligation: function (rows, config) {
    let data = []
    const all = []
    _.each(rows, row => {
      if (row.mandatory) {
        all.push('mandatory')
      }
      if (row.comply_explain) {
        all.push('comply or explain')
      }
      if (row.optional) {
        all.push('optional')
      }
    })
    _.each(_.countBy(all, x => x), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  topicCount: function (rows, config) {
    let data = []
    const toCount = []
    rows.forEach(row => {
      if (_.findIndex(row.impactpolicy.topics, ['id', config.topicId]) !== -1) {
        const line = { country: row.country }
        toCount.push(line)
      }
    })
    _.each(_.countBy(toCount, 'country'), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  property: function (rows, config) {
    let data = []
    _.each(_.countBy(rows, x => x[config.property]), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  propertySum: function (rows, config) {
    let all = []
    const accumulator = {}
    let data = []
    _.each(rows, row => {
      all.push(row[config.property])
    })
    all = _.uniq(all)
    _.each(all, property => {
      accumulator[property] = 0
    })
    _.each(rows, row => {
      accumulator[row[config.property]] = accumulator[row[config.property]] + row[config.sumProperty]
    })
    _.each(accumulator, (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  workflow: function (rows, config) {
    let data = []
    _.each(_.countBy(rows, x => x[config.property]), (value, key) => {
      const w = config.workflows.find(y => y.id === parseInt(key))
      const name = w.name
      data.push({ name: name, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  }
}
