export default {
  color: [
    '#00b8f5', // pacific blue
    '#00348d', // kpmg blue
    '#1e49e2', // cobalt blue
    '#0c233c', // dark blue
    '#aceaff', // light blue
    // '#00b8f5', // pacific blue
    '#7213ea', // purple
    '#fd349c', // pink
    '#76d1ff', // blue
    '#500dbc', // dark purple
    '#b497ff', // light purple
    '#ab0d81', // dark pink
    '#ffa3da', // light pink
    '#098e7e', // dark green
    '#00c0ae', // green
    '#63ebdb' // light green
  ]
}
